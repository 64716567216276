/* eslint-disable max-lines */
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { TranslateService } from '@ngx-translate/core';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import jwtDecode from 'jwt-decode';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  firstValueFrom,
  of,
} from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
import { RolesRemovedDialogComponent } from '../roles-removed-dialog/roles-removed-dialog.component';
import { AuthTokens } from '../shared-feat-auth.tokens';
import { OidcConfig } from '../utils/oidcConfig';
import { UserContext } from './user-context';
import { UserContextService } from './usercontext.service';

/**
 * Provides all user relevant authentication logic
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _isAuthorized = false;
  private _isAuthorized$ = new ReplaySubject<boolean>(1);
  private _isTriggeredSilentRenewRunning$ = new BehaviorSubject<boolean>(false);
  private _invalidRefreshToken$ = new Subject<void>();

  private checkSessionIframeRefreshIntervalId: number | undefined;

  private readonly signOutRoute = '/special/signout';
  private readonly callbackRoute = '/callback';

  constructor(
    @Optional() private oauthService: OAuthService,
    public router: Router,
    @Optional() public usercontextService: UserContextService,
    @Optional() public messageService: MessengerService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private http: HttpClient,
    @Optional() private snackbar: DsSnackbar,
    @Optional()
    @Inject(AuthTokens.securityTokenService)
    private securityTokenService: string,
    @Optional()
    @Inject(AuthTokens.oidcClientId)
    private oidcClientId: string,
    @Optional()
    @Inject(AuthTokens.postLogoutRedirectUri)
    private postLogoutRedirectUri: string,
    @Optional() @Inject(AuthTokens.oidcScope) private oidcScope: string,
    @Optional() @Inject(AuthTokens.isMobileApp) private isMobileApp = false,
  ) {
    if (!this.oauthService) {
      return;
    }

    if (
      !securityTokenService ||
      !oidcClientId ||
      !postLogoutRedirectUri ||
      !oidcScope ||
      !usercontextService
    ) {
      console.error('Please provide all dependencies for the userService');
    }

    this.oauthService.configure(
      OidcConfig.Configuration(
        this.securityTokenService,
        this.oidcClientId,
        this.oidcScope,
        this.postLogoutRedirectUri,
        this.isMobileApp,
      ),
    );

    this.setupOAuthServiceEventsHandling();

    if (isMobileApp) {
      const userInfo = this.oauthService.getIdentityClaims();
      if (userInfo) {
        // try to get fresh tokens and user data. if it fails, use the current user data.
        this.oauthService.refreshToken().catch(() => {
          this.setUserContext(userInfo);
        });
      } else {
        this.setAuthenticated(false);
      }
    } else {
      // get tokens if this is redirect back from identity server,
      // initiate silent refresh otherwise
      this.requestTokensIfRedirectFromAuthorizationServer().then((success) => {
        if (!success) {
          return;
        }

        if (window.location.pathname === this.callbackRoute) {
          this.redirectToRequestedUrl();
        } else {
          this.loginWithSilentRenew();
        }
      });
    }

    this.oauthService.setupAutomaticSilentRefresh();
  }

  private setupOAuthServiceEventsHandling(): void {
    // get userinfo after a token is received
    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe(() => {
        this.logTrace('Token received.');
        this.getUserInfo(!this.isAuthorized);
      });

    // set up single sign out
    this.oauthService.events
      .pipe(filter((e) => e.type === 'session_terminated'))
      .subscribe(() => {
        this.logTrace('Session terminated.');
        this.navigateToSignOutPage();
      });

    // if a refresh token is invalid, the user is not logged out.
    // instead, tokenRefreshFailed$ emits an event, so that the app can react to it.
    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_refresh_error'))
      .subscribe((e) => {
        const reason = (e as OAuthErrorEvent).reason as HttpErrorResponse;
        if (this.isAuthorized && reason.status === 400) {
          this._invalidRefreshToken$.next();
        }
        this.logWarning('Token refresh error.', reason.error);
      });
  }
  public requestTokensIfRedirectFromAuthorizationServer(): Promise<boolean> {
    // state query param value is copied to a local variable,
    // because the query string is removed from url by oauthService.tryLogin()
    const stateQueryParam = this.getStateQueryParam();

    return this.oauthService.tryLogin().catch((error) => {
      this.handleTryLoginError(error, stateQueryParam);
      return false;
    });
  }

  /**
   * This method allows you to override the method that is used to open the login url.
   * This is useful when you want to open the login url in an in-app browser in a mobile app.
   */
  public setOpenUri(openUri: ((uri: string) => void) | undefined): void {
    (this.oauthService as any).config.openUri = openUri;
  }

  private getStateQueryParam(): string | null {
    const params = new HttpParams({ fromString: window.location.search });
    return params.get('state');
  }

  private handleTryLoginError(
    error: OAuthErrorEvent | string,
    stateQueryParam: string | null,
  ): void {
    const errorMessage = 'Error from oauthService.tryLogin().';

    if (error === 'Token has expired') {
      this.displayGeneralError('general.error_code.sign_in_wrong_time');

      const accessToken = this.oauthService.getAccessToken();
      const username: string = accessToken
        ? jwtDecode<any>(accessToken, {}).username
        : '';
      this.logError(errorMessage, error, '', username);
    } else if (
      error instanceof OAuthErrorEvent &&
      error.type === 'invalid_nonce_in_state'
    ) {
      const nonceInStorage = sessionStorage.getItem('nonce');
      const detailText = `State query param invalid. Value from request: ${stateQueryParam}. Value in storage: ${nonceInStorage}`;
      this.logError(errorMessage, error, detailText);
      // try to login again
      // see https://dev.azure.com/palfinger-swdev/Palfinger.Paldesk/_git/Palfinger.Paldesk.Dashboard/pullrequest/17267
      // and https://palfinger-swdev.visualstudio.com/DefaultCollection/Palfinger.Paldesk/_git/Palfinger.Paldesk.Dashboard/pullrequest/29714
      this.loginWithSilentRenew().then((success) => {
        if (success) {
          this.redirectToRequestedUrl();
        } else {
          this.displayGeneralError();
        }
      });
    } else {
      this.logError(errorMessage, error);
      this.displayGeneralError();
    }
  }

  get isAuthorized(): boolean {
    return this._isAuthorized;
  }

  get isAuthorized$(): Observable<boolean> {
    return this._isAuthorized$.asObservable();
  }

  get invalidRefreshToken$(): Observable<void> {
    return this._invalidRefreshToken$.asObservable();
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  get userContext(): UserContext {
    return this.usercontextService.userContext;
  }

  get currentUser(): Observable<UserContext | undefined> {
    return this.usercontextService?.currentUser || of(undefined);
  }

  login() {
    this.logTrace('Start login.');
    this.oauthService.initCodeFlow();
  }

  /**
   * Tries to refresh tokens and user info.
   */
  async tryRefreshSession(): Promise<void> {
    this.logTrace('Start refresh login session.');
    const isSilentRenewRunning = await firstValueFrom(
      this._isTriggeredSilentRenewRunning$,
    );

    if (isSilentRenewRunning) {
      await firstValueFrom(
        this._isTriggeredSilentRenewRunning$.pipe(
          filter((isRunning) => !isRunning),
        ),
      );
    } else {
      this._isTriggeredSilentRenewRunning$.next(true);

      if (this.isMobileApp) {
        await this.oauthService.refreshToken().catch(() => {
          // reaction to this error is set up in the constructor as a reaction to the token_refresh_error event
        });
      } else {
        await this.oauthService.silentRefresh().catch((error) => {
          this.logWarning('Silent refresh error.', error);
        });
      }

      this._isTriggeredSilentRenewRunning$.next(false);
    }
  }

  /**
   * Removes tokens and user data from browser storage without sending anything to Identity Server.
   *
   * It can be used when a user is not logged-in in Identity Server,
   * but user data is found in the storage.
   */
  logoutLocally(): void {
    this.oauthService.logOut(true);
    this.setAuthenticated(false);
  }

  logout(): void {
    this.logTrace('Start logoff.');
    sessionStorage.removeItem(this.redirectPathStorageKey);
    this.oauthService.logOut();
    this.setAuthenticated(false);
  }

  navigateToProfile(): void {
    window.open(this.securityTokenService + '/profile', '_blank');
  }

  /**
   * Gets time of Identity Server login expiration
   */
  public getLoginValidUntil(): Observable<Date | undefined> {
    return this.isAuthorized$.pipe(
      first((isAuthorized) => !!isAuthorized),
      switchMap(() =>
        this.http.get<number>(
          `${this.securityTokenService}/user/sessionexpiration`,
          {
            withCredentials: true,
          },
        ),
      ),
      map((sessionExpiration) => {
        const expireDate = new Date(0);
        expireDate.setUTCSeconds(sessionExpiration);
        return expireDate;
      }),
      catchError(() => of(undefined)),
    );
  }

  /**
   * Checks if current loggedIn User has one of the role
   * @param roles
   */
  public hasOneRole(roles: string[] | undefined): boolean {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.roles &&
      this.usercontextService.userContext.roles.length > 0 &&
      roles &&
      roles.length > 0
    ) {
      for (const reqRole of roles) {
        if (this.usercontextService.userContext.roles.indexOf(reqRole) > -1) {
          return true;
        }
      }
    }
    return false;
  }
  /**
   * Check if current loggedIn User has a specific role
   * @param role
   */
  public hasRole(role: string): boolean {
    const roles: string[] = [role];
    return this.hasOneRole(roles);
  }
  /**
   * Check if current loggedIn User has all Roles
   * @param roles
   */
  public hasAllRoles(roles: string[]): boolean {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.roles &&
      this.usercontextService.userContext.roles.length > 0 &&
      roles &&
      roles.length > 0
    ) {
      for (const reqRole of roles) {
        if (this.usercontextService.userContext.roles.indexOf(reqRole) === -1) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  /**
   * Check if user has a role with provided appId
   * @param appId
   */
  public hasApplication(appId: string): boolean {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.roles &&
      appId
    ) {
      return !!this.usercontextService.userContext.roles.find((x) =>
        x.toLowerCase().startsWith(appId.toLowerCase()),
      );
    }
    return false;
  }

  /**
   * Check if a user has an accesscode
   * @param code
   */
  public hasAccessCode(code: string) {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.products &&
      code
    ) {
      return !!this.usercontextService.userContext.products.find(
        (x) => x.toLowerCase() === code,
      );
    }
    return false;
  }
  /**
   * Check if user is one of partner types
   * @param partnerTypes
   */
  public isOneOfPartnerTypes(partnerTypes: number[]) {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.partnertype &&
      partnerTypes &&
      partnerTypes.length > 0 &&
      partnerTypes.indexOf(this.usercontextService.userContext.partnertype) > -1
    ) {
      return true;
    }
    return false;
  }
  /**
   * Check if a user is partner type
   * @param partnerType
   */
  public isPartnerType(partnerType: number) {
    const partnerTypes: number[] = [partnerType];
    return this.isOneOfPartnerTypes(partnerTypes);
  }

  /**
   * Check if user's company area is PALFINGER GmbH
   */
  public isGermany() {
    return this.userContext.area_sapid_nr === '0000001104';
  }

  /**
   * Internal method to set authentication status
   * @param authenticated
   */
  public setAuthenticated(authenticated: boolean): void {
    this._isAuthorized = authenticated;
    this._isAuthorized$.next(this._isAuthorized);
    this.setCheckSessionIframeRefreshInterval(authenticated);
  }

  private setCheckSessionIframeRefreshInterval(authenticated: boolean): void {
    clearInterval(this.checkSessionIframeRefreshIntervalId);
    this.checkSessionIframeRefreshIntervalId = authenticated
      ? window.setInterval(() => {
          this.oauthService.restartSessionChecksIfStillLoggedIn();
        }, 60000)
      : undefined;
  }

  public navigateToSignOutPage() {
    const location = window.location.pathname + window.location.search;
    if (location !== this.signOutRoute) {
      this.setRedirectPath(location);
      this.ngZone.run(() => this.router.navigate([this.signOutRoute]));
    }
  }

  public setRedirectPath(path: string): void {
    if (path !== this.callbackRoute) {
      sessionStorage.setItem(this.redirectPathStorageKey, path);
    }
  }

  /**
   * Gets initial tokens and user data with no visible redirect to authorization server.
   * For token renewal, use tryRefreshSession() instead.
   */
  private loginWithSilentRenew(): Promise<boolean> {
    this.logTrace('Initiate silent refresh.');
    return this.oauthService
      .silentRefresh()
      .then((event) => {
        if (event instanceof OAuthErrorEvent) {
          this.logTrace('Login with silent renew failed.', event);
          this.setAuthenticated(false);
          return false;
        }
        return true;
      })
      .catch((error) => {
        this.logTrace('Login with silent renew failed.', error);
        this.setAuthenticated(false);
        return false;
      });
  }

  private setUserContext(userInfo: object): void {
    const userContext = userInfo as UserContext;
    const language = userContext.lang?.toLowerCase();

    if (language && language !== this.translateService.currentLang) {
      this.translateService.resetLang(language); // we need this as a workaround to a ngx-translate bug from 2007 :/ https://github.com/ngx-translate/core/issues/749
      this.translateService.use(language);
    }

    // if some of the roles were removed, display the warning dialog
    if (
      this.isAuthorized &&
      this.userContext?.roles.find((role) => !userContext.roles.includes(role))
    ) {
      this.dialog.open(RolesRemovedDialogComponent, {
        maxWidth: '700px',
        disableClose: true,
      });
    }

    this.usercontextService.setUser(userContext);
    this.setAuthenticated(true);
  }

  private redirectToRequestedUrl(): void {
    const redirectPath = sessionStorage.getItem(this.redirectPathStorageKey);
    if (redirectPath) {
      this.logTrace(`Redirecting user to path:${redirectPath}.`);
      sessionStorage.removeItem(this.redirectPathStorageKey);
      this.router.navigateByUrl(redirectPath);
    } else {
      this.logTrace('Redirecting user to root');
      this.router.navigate(['']);
    }
  }

  private getUserInfo(displayErrors = true): void {
    this.oauthService
      .loadUserProfile()
      .then((userInfo: any) => {
        this.logTrace('User info loaded.');
        this.setUserContext(userInfo.info);
      })
      .catch((error) => {
        this.logError('Error loading user info', error);
        if (displayErrors) {
          this.displayGeneralError();
        }
      });
  }

  /**
   * Stores url path which user want's to visit before he is redirected to IdentityServer login page.
   * After he is succesfully logged in, then we will use this value and redirect user there
   */
  private get redirectPathStorageKey() {
    return this.oidcClientId + '_redirect';
  }

  private displayGeneralError(
    messageTranslationKey = 'general.error_code.error',
  ): void {
    const message = this.translateService.instant(messageTranslationKey);
    if (this.snackbar) {
      this.snackbar.queue(message, {
        type: DsSnackbarType.Error,
        duration: 20000,
      });
    } else {
      this.messageService.sendDetailMessage({
        severity: MessageSeverityType.error,
        message,
        targets: MessageTargetType.toast,
      });
    }
  }

  private logTrace(message: string, detailObject?: any) {
    this.messageService.sendDetailMessage({
      severity: MessageSeverityType.trace,
      message,
      detailObject,
      source: 'UserService',
      targets: [MessageTargetType.console, MessageTargetType.log],
    });
  }

  private logWarning(message: string, detailObject?: any, detailText?: string) {
    this.messageService.sendDetailMessage({
      severity: MessageSeverityType.warning,
      message,
      detailObject,
      detailText,
      source: 'UserService',
      targets: [MessageTargetType.console, MessageTargetType.log],
    });
  }

  private logError(
    message: string,
    detailObject?: any,
    detailText?: string,
    username?: string,
  ) {
    this.messageService.sendDetailMessage({
      severity: MessageSeverityType.error,
      message,
      detailObject,
      detailText,
      source: 'UserService',
      targets: [MessageTargetType.console, MessageTargetType.log],
      username,
    });
  }
}
