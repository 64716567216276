import { Component } from '@angular/core';
import { UserService } from '@features/auth';
@Component({
  selector: 'ps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // The UserService needs to be added here as a dependency to make sure it is instantiated,
  // otherwise the user is not logged in when the app starts.
  // It is not needed when the AppComponent template contains <ds-app-wrapper>
  // because the app wrapper component already has a dependency on the UserService.
  constructor(private userService: UserService) {}
}
