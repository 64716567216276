import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { AuthGuard } from '@features/auth';
import { LayoutComponent } from './layout/layout.component';
import { inject } from '@angular/core';
import { HelpLayoutComponent } from './layout/help-layout/help-layout.component';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'calculation',
      },
      {
        path: 'calculation',
        loadChildren: () =>
          import('./projects/calculations/calculations.module').then(
            (m) => m.CalculationsModule,
          ),
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'help',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    component: HelpLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@paldesk/design-system/feature/help-page').then(
            (m) => m.DSHelpPageModule,
          ),
      },
    ],
  },
  {
    path: 'embeded',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    loadChildren: () =>
      import('./embeded/embeded.module').then((m) => m.EmbededModule),
  },
  {
    path: 'embeded-loader',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    loadChildren: () =>
      import('./embeded-loader/embeded-loader.module').then(
        (m) => m.EmbededLoaderModule,
      ),
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
