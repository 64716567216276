import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  AppWrapperModule,
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper-v2';
import { RootEffects, RootState, rootReducer } from '@features/root-store';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from '@shared-lib/google-analytics';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core.module';
import { HelpLayoutComponent } from './layout/help-layout/help-layout.component';
import { LayoutComponent } from './layout/layout.component';
import { SharedModule } from './shared/shared.module';

interface State {
  root: RootState;
  // router: RouterReducerState;
}

const coreReducers: ActionReducerMap<State, any> = {
  root: rootReducer,
  // router: routerReducer,
};

@NgModule({
  declarations: [AppComponent, LayoutComponent, HelpLayoutComponent],
  imports: [
    SharedModule,
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    // Store
    StoreModule.forRoot(coreReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([RootEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      connectInZone: true,
    }),
    // Shared
    SharedLibFeatTranslationModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: (): DsAppWrapperConfig => ({
          appName: 'Palshow',
          environment: environment.environment,
          apiBasePath: environment.palfinger_api_management,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          appGatewayBasePath: environment.palfinger_app_gateway,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appHelpPath: environment.LINKS.APP_HELP_PATH,
          statusBannerProductsToCheck: [],
        }),
      },
    ]),
    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.google_analytics_id,
    }),
    ReactiveFormsModule,
    FormsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
