import { Component } from '@angular/core';
@Component({
  selector: 'ps-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  isLoaded = false;

  ngOnInit(): void {
    this.isLoaded = true;
  }
}
