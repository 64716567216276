import { TranslateService } from '@ngx-translate/core';
import { TableSettingsIntl } from '@design-system/components/table-settings';

export class TableSettingsI18n {
  constructor(private readonly translate: TranslateService) {}

  getIntl(): TableSettingsIntl {
    const intl = new TableSettingsIntl();
    intl.getColumnName = this.getColumnName.bind(this);

    return intl;
  }

  private getColumnName(column: string): string {
    return this.translate.instant(`projects_table.columns.${column}`);
  }
}
